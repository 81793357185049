import { useNavigate } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'
import { CollapseProps, Divider, Skeleton } from 'antd'

import * as S from './style'

import { useAuthStore } from '@/app'
import { NAV_ROUTES, getRedirectHref, useUserId, useAuth } from '@/common'
import { useUsername } from '@/common/hooks/useUsername'
import { PageHeader } from '@/components'
import { systemQueries } from '@/features/systems'
import { PageLayout } from '@/layouts/PageLayout'

export const HomePage = () => {
  const userId = useUserId()
  const navigate = useNavigate()
  const { username } = useUsername()
  const tokens = useAuthStore((state) => state.tokens)
  const { userAllowedModules } = useAuth()

  const { data: userHomeInfo, isLoading, isError, isFetched } = useQuery(systemQueries.homeInfo())

  const SystemCards = () => {
    return (
      <S.LinkCardsContainer>
        {isFetched &&
          userHomeInfo?.systems?.map((system) => {
            const systemHref = getRedirectHref({
              systemUrl: system.system_path,
              refreshToken: tokens?.refreshToken as string,
              userId,
            })

            return (
              <S.LinkCard
                href={systemHref}
                target="_blank"
                rel="noopener noreferrer"
                key={system.system_pk}
              >
                {system.system_name}
              </S.LinkCard>
            )
          })}
      </S.LinkCardsContainer>
    )
  }

  const ConfigCards = () => (
    <S.ConfigCardsContainer>
      {NAV_ROUTES?.map(({ path, label, icon, key, allowedRoles }) => {
        // Remove home from config cards
        if (path === '/home') return null

        // Remove module if user is not allowed to see it
        if (
          allowedRoles &&
          !userAllowedModules?.includes('*') &&
          !userAllowedModules?.some((role) => allowedRoles.includes(role))
        ) {
          return null
        }

        let title = label

        if (key === 'logs') {
          title = key
        }

        if (key === 'mfa') {
          title = key.toUpperCase()
        }

        return (
          <S.ConfigCard key={path} onClick={() => navigate(path)}>
            {icon}

            <Divider />

            <S.CardTitle>{title}</S.CardTitle>
          </S.ConfigCard>
        )
      })}
    </S.ConfigCardsContainer>
  )

  const systemAreaCollapseItems: CollapseProps['items'] = userHomeInfo?.system_links?.map(
    (systemLink, i) => {
      const collapseKey = (i + 1 + 1).toString()

      return {
        key: collapseKey,
        label: (
          <S.CollapseDivider orientation="left" orientationMargin="0">
            {systemLink.area}
          </S.CollapseDivider>
        ),
        children: (
          <S.LinkCardsContainer>
            {systemLink.links.map((link) => {
              const systemHref = getRedirectHref({
                systemUrl: link.system_path,
                refreshToken: tokens?.refreshToken as string,
                userId,
                systemPage: link.page_path,
              })

              return (
                <div key={link.id}>
                  <S.SystemLinkCard href={systemHref} target="_blank" rel="noopener noreferrer">
                    {link.page_name}
                    <span>{link.system_name}</span>
                  </S.SystemLinkCard>
                </div>
              )
            })}
          </S.LinkCardsContainer>
        ),
      }
    },
  )

  const collapseItems: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <S.CollapseDivider orientation="left" orientationMargin="0">
          Sistemas
        </S.CollapseDivider>
      ),
      children: <SystemCards />,
    },
    ...(systemAreaCollapseItems || []),
    {
      key: 'config',
      label: (
        <S.CollapseDivider orientation="left" orientationMargin="0">
          Configurações
        </S.CollapseDivider>
      ),
      children: <ConfigCards />,
    },
  ]

  const filteredCollapseItems = collapseItems.filter((item) => {
    // Show system cards only if user has systems
    if (item.key === '1') {
      if (isError || !userHomeInfo || userHomeInfo?.systems?.length === 0) {
        return false
      }

      return true
    }

    return true
  })

  const SkeletonPage = () => (
    <S.LoadingContainer>
      <S.SkeletonHeader active block size={'large'} />
      <Skeleton active />
      <S.SkeletonHeader active block size={'large'} />
      <Skeleton active />
      <S.SkeletonHeader active block size={'large'} />
      <Skeleton active />
    </S.LoadingContainer>
  )

  return (
    <PageLayout title="Home" isListPage={false}>
      <S.PageTitle>
        <PageHeader title={`Seja bem-vindo(a), ${username}`} />
      </S.PageTitle>

      {isLoading ? (
        <SkeletonPage />
      ) : (
        <S.Collapse
          ghost
          size="large"
          defaultActiveKey={['1', '2']}
          items={filteredCollapseItems}
        />
      )}
    </PageLayout>
  )
}
